/* eslint-disable prettier/prettier */
module.exports = {
    //-- SITE SETTINGS -----
    author: "@KynanBertulli",
    siteTitle: "Kynan Bertulli - Portfolio",
    siteShortTitle: "Kynan Bertulli",
    siteDescription: "",
    siteUrl: "https://gatsby-starter-portfolio-minimal.netlify.app/",
    siteLanguage: "en_US",
    siteIcon: "content/favicon.png", // Relative to gatsby-config file
    seoTitleSuffix: "Portfolio Minimal", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
    useCookieBar: false, // If you use Google Analytics and want to be GDPR-compliant, set it to true
    googleAnalyticsTrackingId: "", // e.g. UA-XXXXXX-X

    // -- THEME SETTINGS -----
    colors: {
        lightTheme: {
            primary: "#000000",
            secondary: "#FFF4D9",
            tertiary: "#F2F2F2",
            text: "#000000",
            subtext: "#555555",
            background: "#FFFFFF",
            card: "#FFFFFF",
            scrollBar: "rgba(0, 0, 0, 0.5)",
            boxShadow: "rgba(0, 0, 0, 0.16)",
            boxShadowHover: "rgba(0, 0, 0, 0.32)",
        },
        darkTheme: {
            primary: "#FAFAFA",
            secondary: "#2A2926",
            tertiary: "#252525",
            text: "rgba(255, 255, 255, 0.87)",
            subtext: "#AAAAAA",
            background: "#121212",
            card: "#1C1C1C",
            scrollBar: "rgba(255, 255, 255, 0.5)",
            boxShadow: "rgba(0, 0, 0, 0.16)",
            boxShadowHover: "rgba(0, 0, 0, 0.32)",
        },
    },
    fonts: {
        primary: "Roboto, Arial, sans-serif",
    },

    //-- ARTICLES SECTION SETTINGS -----
    // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
    // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
    // mediumRssFeed: "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40konstantinmuenster",

    // shownArticles: 3,

    socialMedia: [{
            name: "Linkedin",
            url: "https://www.linkedin.com/in/kynan-bertulli-0b6529b8/",
        },

        {
            name: "Github",
            url: "https://github.com/KynanBertulli/",
        },
    ],

    //-- NAVIGATION SETTINGS -----
    navLinks: {
        menu: [{
                name: "About Me",
                url: "/#about",
            },
            {
                name: "Features",
                url: "/#projects",
            },
            {
                name: "Contact",
                url: "/#contact",
            },
        ],
        button: {
            useFileName: true,
            name: "Resume",
            fileName: "Kynan_Bertulli-Resume.pdf", // the file has to be placed inside the static folder at the root level
            url: "", // if useFileName=false, you can set an anchor link here and use the button for navigational purposes
        },
    },
    footerLinks: [{
            name: "Privacy",
            url: "/privacy",
        },
        {
            name: "Imprint",
            url: "/imprint",
        },
    ],
}